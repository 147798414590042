// @flow
import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import {
  Subtitle,
  Title,
  DescriptionView,
  Description,
  DescriptionText,
  DescriptionTitleGroup,
} from '../components/styles'
import '../css/main.min.css'
import '../css/styles.min.css'

type Props = {
  data: Object,
}

export default class Index extends React.Component<Props> {
  render() {
    return (
      <Layout data={this.props.data}>
        <DescriptionView>
          <DescriptionTitleGroup>
            <Subtitle>Welcome To</Subtitle>
            <Title>An Engineering Career Ladder 🎉</Title>
          </DescriptionTitleGroup>
          <Description>
            <DescriptionText>
              These are some sample Engineering career ladders built over the last few years, primarily from work done at <a href="https://glitch.com">Glitch</a>. The
              framework, mechanics and structure of the site are drawn from the amazing
              work done by <a href="https://progression.monzo.com"> Monzo</a>.
            </DescriptionText>
            <DescriptionText>
              Career ladders are tools that can help us evaluate, assess, and
              compensate Engineering staff transaprently and consistently.
            </DescriptionText>
            <DescriptionText>
              Ladders help people understand how to progress their career and
              professional development.
            </DescriptionText>
            <DescriptionText>
            These ladders also owe a debt to prior art from Glitch, Kickstarter, Rent
              the Runway, Foursquare, Nava, Glossier, and Monzo.
            </DescriptionText>
            <DescriptionText>
              Take a look around and let us know <a href="https://github.com/jamtur01/career-ladder">what you think</a>! 🚀
            </DescriptionText>
          </Description>
        </DescriptionView>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            path
            sidebarTitle
            sidebarGroup
          }
        }
      }
    }
  }
`
